import React, { useState, useEffect } from 'react';
import styles from "./emisoraCom.module.css";
import EmisoraPublicidadDos from '../utils/EmisoraPublidadDos';


const MusicInfo = ({ emisoraid }) => {
  const [apiData, setApiData] = useState(null);
  const apiUrl = `https://api-v2.acrcloud.com/api/bm-cs-projects/9096/streams/${emisoraid}/results?type=day&min_duration=120&with_false_positive=1&max_duration=500`;
  const accessToken = process.env.REACT_APP_ACCESS_TOKEN;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error(`Error en la respuesta de la API: ${response.statusText}`);
        }

        const data = await response.json();
        setApiData(data);
      } catch (error) {
        console.error('Error al obtener datos:', error);
      }
    };

    fetchData();
  }, []); // Cambiado el array de dependencias a []

  return (
    <div>
      {apiData && apiData.data && apiData.data.length > 0 ? (
        <div className={styles.divsonadas}>
          
          <div className={styles.divcanciones}>

            <h3>Canciones sonandas:</h3>
          <div className={styles.canciones}>
          {apiData.data
            .slice(-20)
            .reverse()
            .map((song, index) => {
              if (song.metadata && song.metadata.music && song.metadata.music.length > 0) {
                // Obtener la fecha actual
                const fechaActual = new Date();
                
                // Obtener la marca de tiempo de la canción y restar 5 horas (UTC-5)
                const fechaCancion = new Date(song.metadata.timestamp_utc);
                fechaCancion.setHours(fechaCancion.getHours() - 5);
  
                // Calcular la diferencia en minutos
                const minutosTranscurridos = Math.floor((fechaActual - fechaCancion) / (1000 * 60));
  
                return (
                  <div  className={styles.sonada} key={index}>
                    <div  className={styles.divminutos}>
                      <p>{`hace ${minutosTranscurridos} minutos`}</p>
                    </div>
                    <div>
                      <p className={styles.artista}>{song.metadata.music[0].artists.map((artist) => artist.name).join(', ')}</p>
                      <p>{song.metadata.music[0].title}</p>
                    </div>
                  </div>
                );
              }
              return null;
            })}
            </div>
            
        </div>
        <div  className={styles.publicidad}>
            <EmisoraPublicidadDos />
            </div>
        </div>
        
      ) : (
        <h1></h1>
      )}
    </div>
  );
  
  
};

export default MusicInfo;
