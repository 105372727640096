import { useSpring, animated } from 'react-spring';
import styles from "./emisorasGrid.module.css";
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export default function MenuLat(){


    const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State for sidebar visibility
    const sidebarAnimation = useSpring({
        transform: isSidebarOpen ? 'translateX(0%)' : 'translateX(-100%)',
        config: {
          duration: 300, // Ajusta la duración en milisegundos (ms)
        },
      });
      const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
      };
      const closeSidebar = () => {
        setIsSidebarOpen(false);
      };


    return (
        <div>
             <button className={styles.sidebarButton} onClick={toggleSidebar}><img src="../img/icon/filter.svg" alt="" /></button>
             <animated.div style={{ ...sidebarAnimation }} className={styles.sidebar}>
      <div>
        <button onClick={closeSidebar}>x</button>
      </div>
      <div>
        <h3>Generos</h3>
        <ul>
          <li>Popular</li>
          <li>Vallenato</li>
          <li>Tropical</li>
          <li>Urbano</li>
        </ul>
      </div>
      <div>
        <h3>sistemas</h3>
        <ul>
          <li>Radio uno</li>
          <li>Olipica</li>
          <li>Mega</li>
          <li>Mix</li>
          <Link to={`/emisoras/rcn`}> <li>Rcn</li></Link>
        </ul>
      </div>
      </animated.div>
        </div>

    );
}