import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import MenuLat from './MenuLat';
import styles from "./emisorasGrid.module.css";
import SinEmisora from './SinEmisoras';
import { Url } from '../constants/global';

const EmisorasList = () => {
  const [titulo, setTitulo] = useState('emisoras');
  useEffect(() => {
    // Actualizar dinámicamente el título en la etiqueta <head>
    document.title = titulo;
  }, [titulo]);

  const [emisoras, setEmisoras] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [pageNumber, setPageNumber] = useState(0);
  
  const emisorasPerPage = 15;
  const navigate = useNavigate();
    //menu desplegable

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`${Url}/emisoras`);
      const data = await response.json();
      setEmisoras(data);
      setPageNumber(0);
    };
    fetchData();
  }, []);

  const filteredEmisoras = emisoras.filter(emisora =>
    emisora.Nombre_emisora.toLowerCase().includes(searchTerm.toLowerCase()) ||
    emisora.nombre_ciudad.toLowerCase().includes(searchTerm.toLowerCase()) ||
    emisora.dial.toString().includes(searchTerm.toLowerCase()) ||
    emisora.Nombre_depto.toLowerCase().includes(searchTerm.toLowerCase()) ||
    emisora.tag_gen.toLowerCase().includes(searchTerm.toLowerCase()) ||
    emisora.media_type.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const pagesVisited = pageNumber * emisorasPerPage;
  const pageCount = Math.ceil(filteredEmisoras.length / emisorasPerPage);

  const displayEmisoras = filteredEmisoras
    .slice(pagesVisited, pagesVisited + emisorasPerPage)
    .map(emisora => (
      <Link className={styles.linkSinSubrayado} key={emisora.id} to={`/emisora/${emisora.stream_id}`}>
        <div className={styles.emisoradiv}>
          <img className={styles.emisoraimg} src={`img/logos/${emisora.stream_id}.jpg`} alt={emisora.Nombre_emisora} />
          <h2>{emisora.Nombre_emisora}</h2>
          <p>{emisora.nombre_ciudad}</p>
          <div>
            <span>{emisora.dial} </span>
            <span>{emisora.band}</span>
            <p>
              <button className={styles.escuchar}
                onClick={(e) => {
                  e.preventDefault(); // Prevenir la navegación predeterminada
                  const externalPageURL = `https://ref.m.letio.com/?ref=${emisora.stream_id}`;
                  const popupOptions = 'width=500,height=2000';
                  window.open(externalPageURL, '_blank', popupOptions);
                }}
              >
                Escuchar Ahora
              </button>
            </p>
          </div>
        </div>
      </Link>
    ));

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const clearSearch = () => {
    setSearchTerm('');
    
  };

  return (
    <div className={styles.buscadordiv}>
      <div className={styles.searchContainer}>
      
        <input
          className={styles.buscador}
          type="text"
          placeholder="Buscar / emisora / ciudad / departamento / dial"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            setPageNumber(e.target.value.length > 0 ? 0 : pageNumber);
          }}
        />

        <button className={styles.clearButton} onClick={clearSearch}><img src="../img/icon/cerrar.svg" alt="" /></button>
          <MenuLat />        
      </div>
      
      {filteredEmisoras.length > 0 ? (
        <div className={styles.emisoraGrid}>{displayEmisoras}</div>
      ) : (
        <SinEmisora searchTerm={searchTerm} />
      )}

        <ReactPaginate
          previousLabel={'Anterior'}
          nextLabel={'Siguiente'}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={styles.pagination}
          activeClassName={styles.active}
          forcePage={pageNumber} // Set forcePage to the current page number
        />
    </div>
  );
};

export default EmisorasList;