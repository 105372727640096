import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import styles from './slider.module.css';



export default function Slider() {
  const swiperRef = useRef(null);

  

  return (
    <div className={styles.divswiper}>
      <Swiper 
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className={styles.swiper}
        
        ref={swiperRef}
      >
        <SwiperSlide>
          <img src="../img/slider/SAN-GIL-SANTANDER.png" alt="" />
        </SwiperSlide>
        <SwiperSlide>
        <img src="../img/slider/OROCUE-CASANARE.png" alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="../img/slider/MONIQUIRA-BOYACA.png" alt="" />
        </SwiperSlide>
        <SwiperSlide>
        <img src="../img/slider/MOCOA-PUTUMAYO.png" alt="" />
        </SwiperSlide>
        <SwiperSlide>
        <img src="../img/slider/CARTAGENA-BOLICA.png" alt="" />
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
