// App.js

import React from 'react';
import EmisorasList from '../components/EmisorasList';
import Slider from '../components/Slider';


const Emisoras = () => {
  return (
    <div>
      <Slider/>  
      <EmisorasList />
    </div>
  );
};

export default Emisoras;
