import styles from "./fohe.css";
import { Link } from "react-router-dom";

export default function Header() {
    return (
        <div className="header">
            <div>
                <img className="logo" src="../img/logos/dialcolombia.png" alt="" />
            </div>
                <nav className="navegacion">
                    <ul>
                        <li>
                        <Link to={`/`}> <p>Home</p></Link>
                        </li>
                        <li>
                        <Link to={`/`}> <p>Nosotros</p></Link>
                        </li>
                        <li>
                        <Link to={`/contacto`}> <p>Contacto</p></Link>
                        </li>
                    </ul>
                </nav>
        </div>
    );
}