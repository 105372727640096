import styles from "./fohe.css";

export default function Header() {
    return (
        <div className="footer">
            <div className="descripcion">
                <p>DialColombia: Tu conexión sonora con el hogar desde cualquier rincón del mundo. Reúnete con la rica diversidad musical y cultural de Colombia estés donde estés. ¡Sintoniza la patria, estés donde estés, con nosotros!</p>
            </div>
            <div className="redes">
                <div>
                    <img src="../img/logos/instagram.png" alt="" />
                </div>
                <div>
                    <img src="../img/logos/facebook.png" alt="" />
                </div>
                <div>
                    <img src="../img/logos/whatsapp.png" alt="" />
                </div>
            </div>
        </div>
    );
}