import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import style from './ifrememisoras.css'; // Estilos específicos para IfremEmisoras
import { EffectCoverflow, Pagination } from 'swiper/modules';
import 'swiper/css';
import './ifrememisoras.css';
import { Url } from '../constants/global';


const IfremEmisoras = () => {
  const [emisoras, setEmisoras] = useState([]);
  const [emisorasCargadas, setEmisorasCargadas] = useState(false);

  useEffect(() => {
    const fetchEmisoras = async () => {
      try {
        const response = await fetch(`${Url}/emisoras`);
        const data = await response.json();
        setEmisoras(data.slice(0, 10)); // Obtener solo las primeras 5 emisoras
        setEmisorasCargadas(true); // Indicar que las emisoras se han cargado
      } catch (error) {
        console.error('Error al obtener las emisoras:', error);
      }
    };

    fetchEmisoras();
  }, []);

  return (
    <div>
      <h2>Listado de Emisoras</h2>
      {emisorasCargadas && ( // Renderizar el Swiper solo cuando las emisoras se hayan cargado
        <Swiper
          loop={true}
          effect={'coverflow'}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={'auto'}
          coverflowEffect={{
            rotate: 30,
            stretch: -100,
            depth: 100,
            modifier: 1,
            slideShadows: true
          }}
          pagination={true}
          modules={[EffectCoverflow, Pagination]}
          className={style.myswiper}
          initialSlide={2} // Aquí establecemos que inicie en el slider 3
        >
          <ul>
            {emisoras.map(emisora => (
              <SwiperSlide className='tarjeta' key={emisora.id}>
                <p>{emisora.Nombre_emisora}</p>
              </SwiperSlide>
            ))}
          </ul>
        </Swiper>
      )}
    </div>
  );
};
export default IfremEmisoras;
