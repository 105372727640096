import React, { useState, useEffect } from "react";
import ErrorPage from "../routes/ErrorPage";
import { useParams } from "react-router-dom";
import styles from "./emisoraCom.module.css";
import EmisoraPublicidad from "../utils/EmisoraPublicidad";
import { Link } from "react-router-dom";
import MusicInfo from './MusicInfo';
import { Url } from "../constants/global";


export default function EmisoraCom() {

  

    const { emisoraid } = useParams();
    const [emisoraData, setEmisoraData] = useState(null);
    

    useEffect(() => {
        const fetchData = async () => {
                const response = await fetch(`${Url}/emisoras/` + emisoraid);
                const data = await response.json();
                setEmisoraData(data);
                document.title = `${data.Nombre_emisora} - ${data.nombre_ciudad} - ${data.dial} ${data.band}`;
        };

        fetchData();
    }, [emisoraid]);
    
    


    return (
        <div className={styles.emisora}>
        
          {emisoraData ? (
            
            <div className={styles.emisoradiv}>
                <div className={styles.emisoraruta}>
                
                    <span><p><Link to="/">DIALCOLOMBIA</Link> &gt;&nbsp;</p></span>
                
                    <span><p><Link to={`/emisoras`}> EMISORAS </Link> &gt;&nbsp;</p></span>
                    <span><p> <Link to={`/emisoras/${emisoraData.Nombre_depto}`}> {emisoraData.Nombre_depto} </Link>&gt;&nbsp;</p></span>
                    <span><p><Link to={`/emisoras/${emisoraData.nombre_ciudad}`}> {emisoraData.nombre_ciudad}</Link></p></span>
                </div>
                <div className={styles.emisorapublicidad}>
                    <div className={styles.emisoramarca}>
                    <div className={styles.contentlogo}>
                        <img className={styles.logo} src={`../img/logos/${emisoraData.stream_id}.jpg`} />
                    </div>
                    <div className={styles.ficha} >
                      <div className={styles.fichanombre}>
                        <h1>{emisoraData.Nombre_emisora} - {emisoraData.nombre_ciudad} </h1>
                        <p className={styles.dial} >{emisoraData.dial} {emisoraData.band}</p>
                      </div>
                      <div className={styles.contbotonplay}>
                        <button className={styles.botonplay}
                        onClick={(e) => {
                            e.preventDefault(); // Prevenir la navegación predeterminada
                            const externalPageURL = `https://ref.m.letio.com/?ref=${emisoraData.stream_id}`;
                            /*&elp=2024-03-15t10:23:05*/
                            const popupOptions = 'width=500,height=2000';
                            window.open(externalPageURL, '_blank', popupOptions);
                          }}>
                            <span className={styles.play} >
                                <img src="../img/icon/play.svg" alt="" />
                                <span>En vivo</span>
                            </span>
                        </button>
                      </div>
                      <div className={styles.contentag}>
                        <span className={styles.tags}>
                          <Link to={`/emisoras/contenido:${emisoraData.tag_contenido}`}> <p style={{textTransform: 'capitalize'}}>#{emisoraData.tag_contenido}&nbsp;</p></Link>
                          <Link to={`/emisoras/${emisoraData.tag_genero_uno}`}> <p style={{ textTransform: 'capitalize' }}>#{emisoraData.tag_genero_uno}&nbsp;</p></Link>
                          <Link to={`/emisoras/${emisoraData.tag_genero_dos}`}><p style={{ textTransform: 'capitalize' }}> #{emisoraData.tag_genero_dos}&nbsp;</p></Link> 
                          <Link to={`/emisoras/${emisoraData.tipo}`}><p style={{ textTransform: 'capitalize' }}>#{emisoraData.tipo}</p></Link>
                        </span>
                      </div>
                    </div>
                    </div>
                    <div className={styles.publicidad}>
                    <EmisoraPublicidad />
                    </div>
                </div>
                <div className={styles.contendes}>
                  <p>
                    {emisoraData.Nombre_emisora} es una emisora radial ubicada en la región de{emisoraData.region}, 
                    específicamente en el departamento de {emisoraData.Nombre_depto}, en la vibrante ciudad de <Link to={`/emisoras/${emisoraData.nombre_ciudad}`}>{emisoraData.nombre_ciudad}</Link>. 
                    Sintonizada en el dial {emisoraData.dial} {emisoraData.band}. 
                    Su sistema es {emisoraData.Sistema} y ofrece una programación variada que combina contenido <Link to={`/emisoras/${emisoraData.tag_contenido}`}>{emisoraData.tag_contenido}</Link> para comunidad local y regional. 
                    gran parte de su contenido se compone de <Link to={`/emisoras/${emisoraData.tag_genero_uno}`}>{emisoraData.tag_genero_uno}</Link>, así como segmentos de <Link to={`/emisoras/${emisoraData.tag_genero_dos}`}> {emisoraData.tag_genero_dos}</Link>.
                  </p>
                </div>
                <div>
                <MusicInfo emisoraid={emisoraid} />
                </div>
            </div>
          ) : (
            <ErrorPage />
          )}
        </div>
      );
}