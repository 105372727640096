
import React from "react";
import EmisorasFiltro from "../components/EmisorasFltro"
import Slider from "../components/Slider";

export default function EmisoraFil() {
    return(
        <div>
        <Slider />
        <EmisorasFiltro />
        </div>
    );
}