import EmisoraCom from "../components/EmisoraCom"
import Slider from "../components/Slider"


export default function Emisora() {
    return (
    <div>
      <Slider />
      <EmisoraCom />
    </div>
);
    } 