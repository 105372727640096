import React from "react";
import styles from "./contacto.css";
import Slider from "../Slider";
import IfremEmisoras from "../IfremEmisoras";

export default function contacto() {
    return (
        <div >
            <Slider  />
            <IfremEmisoras />
        </div>
    );
}